.master-admin-theme {
    font-weight: 400;
    font-family: $font-family-roboto;

    .navbar {
        position: sticky;
        top: 0;
        right: 0;
        align-items: stretch;
        margin-bottom: $unit-md-2;
        padding: 0;
        width: 100%;
        z-index: 90;

        .navbar-right {
            width: 100%;
            height: $unit-md-3;
            padding: $unit-base;
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: $color-paper;
            box-shadow: 0px 0px 6px 0px $color-light-grey-4;
        }
    }

    .navbar-brand {
        padding: $unit-base;
        font-size: 1rem;
        box-shadow: none;
        background-color: $color-speechlink-dark-blue;
    }

    .navbar .form-control {
        padding: 0.75rem 1rem;
        border-width: 0;
        border-radius: 0;
    }

    .breadcrumb {
        background-color: $color-paper;
    }
}
