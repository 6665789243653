//--------------------------------------------------------------
//Product Specific Colours for various elements across the site
//--------------------------------------------------------------

@mixin theme-color($color, $color-dark: "", $color-light: "") {

    #app-menu,
    #app-header,
	#app-footer {
        border-color: $color;
    }

    #product-menu {

        a {
            color: $color;
        }

        .tab-menu > li > a {
            &:hover,
            &:active,
            &:focus,
            &.active-tab {
                border-color: $color;
                background-color: $color;
            }
        }
    }

    // Product landing page anchor links
    .list-product-item {
        a {
            color: $color;

            &:hover,
            &:active,
            &:focus {
                color: $color-speechlink-blue;
            }
        }
    }

    .new-user-jumbotron {
        background-color: $color-light;
        transition: all 0.5s ease;


        h2 {
            color: $color;
            transition: all 0.5s ease;
        }

        p {
            color: $color-dark;
            transition: all 0.5s ease;
        }

        #book,
        #video {
            fill: $color;
            transition: all 0.5s ease;
        }

        &:hover, &:active, &:focus {
            background-color: $color;
            transition: all 0.5s ease;

            h2 {
                color: $color-light;
                transition: all 0.5s ease;
            }

            p {
                color: $color-light;
                transition: all 0.5s ease;
            }

            #book,
            #video {
                fill: $color-light;
                transition: all 0.5s ease;
            }
        }
    }

    // Buttons in product color theme
    .btn-product-color {
        color: #FFF;
        background-color: $color;

        // Assessment States
        &.btn-required, &.btn-advised {
            color: $color;
        }

        // Step-by-Step Guide Sidebar
        .card-guide-notch {
            color: #FFF !important;

            .fa-circle {
                color: $color !important;
            }

            .fa-circle-thin {
                color: #FFF !important;
            }
        }

        &:hover {
            background-color: darken($color, 7%);

            .card-guide-notch {
                .fa-circle {
                    color: darken($color, 7%) !important;
                }
            }
        }

        &:active {
            .card-guide-notch {
                color: #FFF !important;
            }
        }
    }

    .btn-resume {
        //color: $color;
        background-color: lighten($color, 10%);

        &:hover {
            background-color: lighten($color, 5%);
        }
    }

    // cards
    .card-product {
        .card-header {
            background-color: $color;
            border-color: $color-dark;

            .card-title {
                color: $color-paper;
            }
        }
    }

    .card-product-alt {
        .card-header {
            background-color: $color-light;
            border-color: $color;

            .card-title {
                color: $color;
            }
        }

        .card-body {
            a,
            h2 {
                color: $color;
                font-size: $font-size-base;
                font-weight: $font-weight-base;

                &:hover, &:active, &:focus {
                    color: $color-dark;
                }
            }
        }
    }

    //Assessment and Intervention Group Session Progress Bars
    .progress {
        background-color: rgba($color, 0.38);

        .progress-bar {
            background-color: $color;

            &.progress-bar-extra {
                background-color: $color-dark;

                > i {
                    font-size: 21px;
                }
            }
        }
    }

    // Assessment Colours
    #nprogress {
        .bar {
            background: $color;
        }

        .peg {
            box-shadow: 0 0 10px $color, 0 0 5px $color;
        }

        .spinner-icon {
            border-top-color: $color;
            border-left-color: $color;
        }
    }

    .question-wrapper {
        .option-active { // Assessment Chosen Question Option
            border-color: $color;
            box-shadow: 0 0 4px 2px $color;
            outline: 0;
        }

        .btn { // Assessment Interface Buttons
            &:hover {
                background-color: #FFF;
                border-color: $color;
                box-shadow: 0 0 4px 2px $color;
            }

            &:active, &:focus {
                background-color: #FFF;
                border-color: $color;
                box-shadow: 0 0 4px 2px $color;
                outline: 0;
            }
        }
    }

    // Intervention Group Sessions
    .session-data { // Current Session Highlighting
        .session-current {
            dd {
                color: $color;
            }
        }
    }

    #session-completed {
        dd {
            color: $color;
        }
    }

    .session-resources { // Current Session Resources
        .list-group {
            .list-group-item {
                &.active {
                    background-color: $color;
                    border-color: $color;

                    &:hover, &:focus, &:active {
                        background-color: darken($color, 5%);
                        border-color: darken($color, 5%);
                    }
                }
            }

            .resources-sub-list {
                li {
                    a {
                        color: $color;

                        &:hover, &:active, &:focus {
                            color: $color-dark;
                        }
                    }
                }
            }
        }
    }

    .ar-result {

        .ar-batch-group {
            h3 {
                color: $color;
            }
        }

        .ar-cell {
            h2 {
                color: $color;
            }
        }
    }

    .tab-product-color {
        background-color: $color;
    }

    .tab-advised {
        color: $color !important;
        background-color: $color-paper;
    }

    //Text color
    .text-product-color {
        color: $color !important;
    }
}

.speech-theme {
    @include theme-color($color-speech-link, $color-speech-link-dark-orange, $color-speech-link-light-orange);
}

.infant-theme {
    @include theme-color($color-infant-link, $color-infant-link-dark-red, $color-infant-link-light-red);
}

.junior-theme {
    @include theme-color($color-junior-link, $color-junior-link-dark-purple, $color-junior-link-light-purple);
}

.secondary-theme {
    @include theme-color($color-secondary-link, $color-secondary-link-dark-yellow, $color-secondary-link-light-yellow);
}

.pupil-management-theme {
    @include theme-color($color-speechlink-green, $color-speechlink-dark-green, $color-speechlink-light-green);
}

.admin-theme {
    @include theme-color($color-speechlink-blue, $color-speechlink-dark-blue, $color-speechlink-dark-blue);
}


// Non mixin classes for when an individual instance of a specific product colour is needed
.speech-color {
    color: $color-speech-link;
}

.infant-color {
    color: $color-infant-link;
}

.junior-color {
    color: $color-junior-link;
}

.secondary-color {
    color: $color-secondary-link;
}

// Notches for use in tables

.table-in-card,
.table-striped,
.table-bordered,
.printable-report-table {
    .junior-notch,
    .infant-notch,
    .speech-notch,
    .secondary-notch {
        position: relative;
        padding-left: $unit-sm-3;

        &:before {
            content: "";
            position: absolute;
            top: 0%;
            left: 0%;
            width: 0px;
            height: 0px;
            border-bottom: 13px solid transparent;
            border-left-width: 13px;
            border-left-style: solid;
        }
    }

    .junior-notch:before {
        border-left-color: $color-junior-link;
    }

    .infant-notch:before {
        border-left-color: $color-infant-link;
    }

    .speech-notch:before {
        border-left-color: $color-speech-link;
    }

    .secondary-notch:before {
        border-left-color: $color-secondary-link;
    }
}
