.placeholder-304x508 {
    height: 508px;
    width: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    background-image: url('/images/placeholders/304x508.png');

    display: flex;
    justify-content: center;
    align-items: center;
}

.placeholder-960x508-background {
    height: 254px;
    width: 100%;
    background-size: contain;
    background-position: center;
    background-image: url('/images/placeholders/960x508-Persistent.png');
}
