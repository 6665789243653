.btn {
    font-family: $font-family-dosis;
    border-width: 0;
    box-shadow: 0px 1px 2px $color-medium-grey-1;

    // Alternative Button
    &.btn-alt {
        color: $color-main-text;
        background-color: $color-light-grey-3;
        border-color: darken($color-light-grey-3, 5%);

        .fa {
            color: $color-medium-grey-4;
        }

        &:hover,
        &:active {
            color: darken($color-main-text, 10%);
            background-color: darken($color-light-grey-3, 10%);
        }

        &:active {
            box-shadow: inset 0 1px 2px $color-medium-grey-2;
        }
    }

    // Default Button
    &.btn-default {
        color: $color-speechlink-blue;
        // background-color: $white;

        .fa {
            color: $color-ui-default-3;

            &.icon-success {
                color: $color-ui-success-2;
            }

            &.icon-failure {
                color: $color-ui-failure-2;
            }
        }

        &.active {
            box-shadow: inset 1px 1px 2px 0 $color-medium-grey-2;
            background-color: $color-medium-grey-1;
        }

        &.disabled {
            box-shadow: inset 1px 1px 2px 0px rgba($color-medium-grey-3, 0.5);
            background-color: $color-light-grey-3;
        }

        &:hover,
        &:active {
            color: darken($color-ui-default-3, 10%);
            background-color: darken($white, 10%);
        }

        &:active {
            box-shadow: inset 0 1px 2px $color-medium-grey-1;
        }

        &.btn-product-color {
            color: $white;
        }
    }

    // Default Button Disabled
    &.btn-default[disabled] {
        box-shadow: inset 1px 1px 2px 0px rgba($color-medium-grey-3, 0.5);
        background-color: $color-light-grey-3;
    }

    // Primary Button
    &.btn-primary {
        background-color: $color-speechlink-dark-blue;

        .fa {
            color: $color-ui-default-1;
        }
    }

    // Folder Button
    &.btn-folder {
        width: 1.73em;
        height: 1.73em;
        padding: 0;
        flex-shrink: 0;

        .fa-folder {
            position: relative;
            top: 2px;
        }

        .fa-folder-open {
            position: relative;
            top: 2px;
            left: 1px;
        }
    }

    // Arrow Button
    &.btn-arrow {
        width: 1.73em;
        height: 1.73em;
        padding: 2px;

        &.btn-arrow-text {
            width: auto;
            padding-left: 3px;
            font-size: $font-size-base * 0.9;
        }
    }

    // Disabled Button
    &.btn-disabled {
        color: $color-dark-grey-3;
        &.disabled {
            cursor: not-allowed;
        }
    }

    // Help Button
    &.btn-help {
        color: $color-ui-info-3;
        background-color: $color-ui-info-1;

        &:hover {
            color: white;
            background-color: $color-ui-info-3;
        }
    }

    // Radio Button
    &.btn-radio {
        color: $color-speechlink-blue;
        background-color: $white;

        &.active {
            box-shadow: inset 1px 1px 2px 0 $color-medium-grey-2;
            color: $white;
            // background-color: $color-medium-grey-1;
            background-color: $color-speechlink-green;
        }

        &.disabled {
            box-shadow: inset 1px 1px 2px 0px rgba($color-medium-grey-3, 0.5);
            background-color: $color-light-grey-3;
        }

        &:hover,
        &:active {
            color: darken($white, 10%);
            background-color: darken($color-speechlink-green, 10%);
        }

        &:active {
            box-shadow: inset 0 1px 2px $color-medium-grey-1;
        }
    }

    //--------------------------------
    // Assessment Buttons
    //--------------------------------

    // Default Assessment Button
    &.btn-assessment {
        height: 1.73em;
        width: 180px;
        padding: 2px $unit-sm-3;
    }

    // Requried Button
    &.btn-required {
        background-color: #fff;

        &:hover {
            background-color: darken(#fff, 7%);
        }
    }

    // Advised Button
    &.btn-advised {
        background-color: #fff;

        &:hover {
            background-color: darken(#fff, 7%);
        }
    }

    // Optional Button
    &.btn-optional {
        color: #fff;
        background-color: $color-medium-grey-1;

        &:hover {
            background-color: darken($color-medium-grey-1, 7%);
        }
    }

    // Pulsating star animation
    .new-feature {
        position: absolute;
        margin: 0px;
        top: 29px;
        right: 8px;
        color: $color-ui-warning-2;
        animation: pulsate 1s ease-in-out infinite both;
    }

    @keyframes pulsate {
        0% {
            transform: scale(1);
        }
        50% {
            transform: scale(1.5);
        }
        100% {
            transform: scale(1);
        }
    }
}

.no-box-shadow {
    .btn {
        border-width: 1px;
    }
}

/* Icon Grow */
.hvr-icon-grow {
    transition-duration: 0.3s;

    i {
        transition-property: transform;
    }
}

.hvr-icon-grow:hover,
.hvr-icon-grow:focus,
.hvr-icon-grow:active {
    i {
        transform: scale(1.3);
        transform-origin: center right;
    }
}
/* End of Icon Grow*/

//---------------------------------------------------
// Button to Extend a Layout e.g. Open report details must be used with a panel atm
//---------------------------------------------------

.btn {
    &.btn-extend {
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        box-shadow: 1px 1px 2px 0px $color-medium-grey-3;
    }
}
