.banner {
    position: relative;
    padding: 0;
    background-color: $color-speechlink-green;
    background-image: url("/images/marketing/header-bg-image-1.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    overflow: hidden;
    z-index: -50;
}

.video-bg {
    .video-container {
        box-shadow: inset 0 -4px 5px -4px rgba(0, 0, 0, 0.6);

        video {
            position: absolute;
            top: -114px;
            left: 0;
            min-width: 100%;
            min-height: 100%;
            z-index: -100;
        }
    }
}

.product-marketing {
    margin-top: $unit-md-2;
    padding: $unit-md-1;
    text-align: center;

    @include media-breakpoint-up(lg) {
        margin-top: 0;
        padding: $unit-md-3;
    }
}
