#app-footer {
    padding-top: $unit-md-1;
    padding-bottom: $unit-md-2;
    font-size: $font-size-base;

    #app-footer-border {
        margin: $unit-md-1 0;
        border-top-width: 1px;
        border-top-style: solid;
    }

    #browser-diagnostics {
        font-size: $font-size-sm;
        font-weight: 400;

        h2 {
            margin-top: 0;
            font-size: $font-size-sm;
            font-weight: 700;
            line-height: 1.42857;
        }
    }

    #legal {
        margin-top: $font-size-base;
        font-family: $font-family-roboto;
        font-size: $font-size-sm;
        font-weight: 400;
        text-align: center;

        @include media-breakpoint-up(md) {
            text-align: left;
        }

        small {
            display: block;
            margin-bottom: 10.5px;
        }
    }

    .card-info {
        font-family: $font-family-roboto;
        font-size: $font-size-sm;
        font-weight: $font-weight-base;

        .card-title {
            font-size: $font-size-base;
        }
    }
}

// Display correct diagnostic message if JS is enabled or not
.no-js {
    #browser-diagnostics {
        .card-heading {
            color: $color-ui-failure-3;
            background-color: $color-ui-failure-1;
            border-color: $color-ui-failure-3;
        }

        .js-on {
            display: none;
        }
    }
}

.js {
    #browser-diagnostics {
        .js-off {
            display: none;
        }
    }
}
