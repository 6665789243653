.card {
    margin-bottom: $unit-sm-3;
    background-color: $color-light-grey-2;

    &.no-bottom-margin {
        margin-bottom: 0;
    }

    &.option {
        max-width: 30%;

        &.correct {
            background-color: #bde6bf;
        }

        .option-image {
            height: 508px;
        }
    }

}

.card-expandable {
    cursor: pointer;
}
