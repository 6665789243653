//-------------
// Colours
//-------------

// Brand
$color-speechlink-blue:             rgb(0,119,200);     // #0077C8
$color-speechlink-green:            rgb(120,190,32);    // #78BE20
$color-speech-link:                 rgb(232,119,34);    // #E87722
$color-infant-link:                 rgb(186,12,47);     // #BA0C2F
$color-junior-link:                 rgb(109,32,119);    // #6D2077
$color-secondary-link:              rgb(233,186,1);     // #E9BA01

// Anchors | Dark Alternative
$color-speechlink-dark-green:       rgb(104,166,28);    // #68A61C
$color-speechlink-dark-blue:        rgb(0, 89, 148);    // #005599
$color-speech-link-dark-orange:     rgb(207,88,0);      // #CF5800
$color-infant-link-dark-red:        rgb(130,8,32);      // #820820
$color-junior-link-dark-purple:     rgb(79,23,87);      // #4F1757
$color-secondary-link-dark-yellow:  rgb(233,186,1);     // #TE9BA01

// Theme Colours | Light Alternative
$color-speechlink-light-green:      rgb(230,255,210);   // #E0F2C9
$color-speechlink-light-blue:       rgb(207,235,255);   // #CFEBFF
$color-speech-link-light-orange:    rgb(255,230,212);   // #FFE6D4
$color-infant-link-light-red:       rgb(255,237,241);   // #FFD2DB
$color-junior-link-light-purple:    rgb(254,242,255);   // #FEF2FF
$color-secondary-link-light-yellow: rgb(249,234,211);   // #F9EAD3

// Backgrounds
$color-paper:                       rgb(251,251,249);   // #FBFBF9
$color-info-background:             rgb(253,244,231);   // #FDF4E7
$color-warning-background:          rgb(249,234,211);   // #F9EAD3

// Grey Palette
$color-main-text:                   rgb(68,68,67);      // #444443

$color-dark-grey-1:                 rgb(55,55,54);      // #373736
$color-dark-grey-2:                 rgb(47,47,46);      // #2F2F2E
$color-dark-grey-3:                 rgb(42,42,41);      // #2A2A29
$color-dark-grey-4:                 rgb(32,32,31);      // #222221

$color-light-grey-1:                rgb(243,243,241);   // #F3F3F1
$color-light-grey-2:                rgb(238,238,236);   // #EEEEEC
$color-light-grey-3:                rgb(230,230,228);   // #E6E6E4
$color-light-grey-4:                rgb(217,217,215);   // #D9D9D7

$color-medium-grey-1:               rgb(196,196,194);   // #C4C4C2
$color-medium-grey-2:               rgb(162,162,160);   // #A2A2A0
$color-medium-grey-3:               rgb(124,124,123);   // #7C7C7B
$color-medium-grey-4:               rgb(88,88,87);      // #585857

// UI information
$color-ui-info-1:                   rgb(224,242,201);   // #E0F2C9
$color-ui-info-2:                   rgb(120,190,32);    // #78BE20
$color-ui-info-3:                   rgb(97,153,26);     // #61991A
$color-ui-info-4:                   rgb(64,102,17);     // #406611

// UI Warning
$color-ui-warning-1:                rgb(242,233,194);   // #F2E9C2
$color-ui-warning-2:                rgb(255,204,0);     // #FFCC00
$color-ui-warning-3:                rgb(204,163,0);     // #CCA300
$color-ui-warning-4:                rgb(102,82,0);      // #665200
$color-ui-warning-5:                rgb(255,180,2);     // #FFB402
$color-ui-warning-6:                rgb(204,136,0);     // #CC8800

// UI Success + Assessed OK Colour Pallete
$color-ui-success-1:                rgb(212,230,209);   // #D4E6D1
$color-ui-success-2:                rgb(91,204,74);     // #5BCC4A
$color-ui-success-3:                rgb(21,165,26);     // #15A51A
$color-ui-success-4:                rgb(46,102,37);     // #2E6625

// UI Failure + Assessed Severe
$color-ui-failure-1:                rgb(237,205,194);   // #EDCDC2
$color-ui-failure-2:                rgb(230,21,14);     // #E6150E
$color-ui-failure-3:                rgb(204,50,0);      // #CC3200
$color-ui-failure-4:                rgb(153,37,0);      // #992500

// UI Assessed Moderate
$color-ui-moderate-1:               rgb(59,147,197);    // #3B93C5
$color-ui-moderate-2:               rgb(56,139,255);    // #388BFF
$color-ui-moderate-3:               rgb(132,183,255);   // #84B7FF
$color-ui-moderate-4:               rgb(31,76,102);     // #1F4C66
$color-ui-moderate-5:               rgb(0,91,153);      // #005B99

// UI Primary
$color-ui-default-1:                rgb(219,240,255);   // #DBF0FF
$color-ui-default-2:                rgb(38,168,255);    // #26A8FF
$color-ui-default-3:                rgb(0,158,248);     // #009EF8
$color-ui-default-4:                rgb(207,235,255);   // #CFEBFF
$color-ui-default-5:                rgb(23,161,255);    // #17A1FF

// Assessment Result Speech Link | Speech Link Product Specific Colours
$color-ar-speech-link-1:            rgb(255,248,243);   // #FFF8F3
$color-ar-speech-link-2:            rgb(235,215,199);   // #EBD7C7
$color-ar-speech-link-3:            rgb(255,186,133);   // #FFBA85
$color-ar-speech-link-4:            rgb(235,165,113);   // #EBA571
$color-ar-speech-link-5:            rgb(255,230,212);   // #FFE6D4

// Assessment Result Infant | Language Link Product Specific Colours
$color-ar-infant-link-1:            rgb(255,238,241);   // #FFEEF1
$color-ar-infant-link-2:            rgb(242,226,229);   // #F2E2E5

// Assessment Result Junior | Language Link Product Specific Colours
$color-ar-junior-link-1:            rgb(254,245,255);   // #FEF5FF
$color-ar-junior-link-2:            rgb(241,229,242);   // #F1E5F2

// Development Test Colours
$color-test:                        rgb(255,153,204);   // #FF99CC
$color-test-light:                  rgb(255,212,233);   // #FFD4E9
$color-test-dark:                   rgb(230,112,184);   // #E670B8

// Non-product Charting Colours
$color-ui-chart-1:                  rgb(36,174,179);    // #24AEB3
$color-ui-chart-2:                  rgb(155,158,60);    // #9B9E3C
$color-ui-chart-3:                  rgb(235,94,89);     // #EB5E59
$color-ui-chart-4:                  rgb(204,154,96);    // #CC9A60
$color-ui-chart-5:                  rgb(149,145,255);   // #9591FF
$color-ui-chart-6:                  rgb(235,129,196);   // #EB81C4
$color-ui-chart-7:                  rgb(60,158,129);    // #3C9E81
$color-ui-chart-8:                  rgb(78,145,204);    // #4E91CC
$color-ui-chart-9:                  rgb(255,183,0);     // #FFB700

// Active sidebar tab color
$color-active-tab:     darken($color: $color-speechlink-dark-blue, $amount: 5);
$color-active-tab-alt: darken($color: $color-speechlink-dark-blue, $amount: 2);

// Trial Item background color
$color-trial-bg:rgb(255, 232, 243); // #FFD4E9

//-------------------------------------------------
// Standard measurements for padding, margins, etc
//
// based on $font-size-base of 15px (which of course can be used too)
// and multiples of the golden ratio 1:1.618
//-------------------------------------------------

// Set default root font-size to 15px
// (in percentage - easier to make calculations)
:root {
    font-size: 93.75%; // 15px (15/16 * 100)
}

$golden-ratio: 1.618;

$unit-sm-1:    0.236rem;  // ~4px ($unit-sm-2 / $golden-ratio)
$unit-sm-2:    0.382rem;  // ~6px ($unit-sm-3 / $golden-ratio)
$unit-sm-3:    0.618rem;  // ~9px ($unit-base / $golden-ratio)

$unit-base:    1rem;      // 15px

$unit-md-1:    1.618rem;  // ~24px ($unit-base * $golden-ratio)
$unit-md-2:    2.618rem;  // ~39px ($unit-md-1 * $golden-ratio)
$unit-md-3:    4.236rem;  // ~63px ($unit-md-2 * $golden-ratio)

$unit-lg-1:    6.854rem;  // ~102px ($unit-md-3 * $golden-ratio)
$unit-lg-2:    11.089rem; // ~166px ($unit-lg-1 * $golden-ratio)
$unit-lg-3:    17.942rem; // ~269px ($unit-lg-2 * $golden-ratio)

$max-width:    100%;      // $max-width * used to override bootstrap settings
$max-height:   100%;      // $max-height * used to overrise bootstrap settings

//-------------------------------------------------
// Fonts
//-------------------------------------------------

$font-family-roboto:            "Roboto", Helvetica, Arial, sans-serif;
$font-family-roboto-condensed:  "Roboto Condensed", "Arial-Narrow", sans-serif;
$font-family-humanist:          "Humanist", "Times New Roman", Times, serif;
$font-family-dosis:             "Dosis", "Arial-Narrow";
$font-family-oxygen:            "Oxygen Mono", SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;

//-------------------------------------------------
// Mixins
//-------------------------------------------------

@mixin boxShadow {
    box-shadow: 0px 1px 2px $color-medium-grey-1;
}
