.table-scrollable-container {
    max-height: 492px;
    position: relative;
    overflow-y: scroll;


    &.table-scrollable-container-border {
        border-top: $table-border-width solid $table-border-color;
        border-bottom: $table-border-width solid $table-border-color;
    }

    .thead-sticky {
        position: sticky;
        top: -1px;
        z-index: 1;
    }

    .table-checkbox {
        transform: scale(1.5);
    }
}

.table {
    .thead-info {
        th {
            color: $color-ui-info-3;
            background-color: $color-ui-info-1;
            border-color: $color-ui-info-2;
        }
    }

    .table-trial {
        background-color: $color-trial-bg;
    }
}

.table-striped {
    tbody {
        .table-trial {
            background-color: $color-trial-bg !important;
        }
    }
}

.sortable {
    .fas.fa-arrows-alt {
        cursor: grab;
    }
}

.table-key {
    width: 100%;
    display: flex;
    padding: $unit-base;

    &.table-key-toggle {

        .heading {
            flex-basis: 50%;
            font-weight: 700;
        }

        .option {
            text-align:right;
            flex-basis: 50%;

            label {
                font-weight: 700;
            }
        }
    }


    .table-key__heading {
        flex-basis: 20%;
        font-weight: 700;
    }

    .table-key__items {
        flex-basis: 80%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        gap: $unit-base;
        line-height: 0;

        span {
            flex-basis: 33%;
        }
    }
}

colgroup {
    col {
        background-color: $color-light-grey-1;

        &.primary-col {
            background-color: $color-speechlink-light-blue;
            border-color: $color-speechlink-blue;
            color: $color-speechlink-blue;
        }

        &.secondary-col {
            background-color: $color-speechlink-light-green;
            border-color: $color-speechlink-green;
            color: $color-speechlink-green;
        }
    }
}
