//-----------------------------------------------------------
// App Menu Specific Nav
//-----------------------------------------------------------

#app-menu {
    padding: $unit-base;
    border-width: 0;
    margin-bottom: 0;

    .navbar-nav {
        flex-direction: row;

        li {
            z-index: 1600;

            a {
                color: white;

                &.speech-link {
                    background-color: $color-speech-link;
                }

                &.infant-link {
                    background-color: $color-infant-link;
                }

                &.junior-link {
                    background-color: $color-junior-link;
                }

                &.user-action {
                    background-color: $color-dark-grey-1;
                    padding-right: 5px;
                }

                &.help-desk {
                    background-color: $color-speechlink-blue;
                }

                &.pupil-management {
                    background-color: $color-speechlink-green;

                    &.home {
                        width: 31px;
                        background-image: url("/images/ui/ui-sprite.png");
                        background-repeat: no-repeat;
                        background-position: center 5px;
                    }
                }

                &.btn {
                    box-shadow: none;
                }
            }

            &.disabled > a {
                color: $color-medium-grey-1;
                background-color: $color-medium-grey-3;

                &.hvr-pop:hover,
                .hvr-pop:focus,
                .hvr-pop:active {
                    -webkit-animation-iteration-count: 0;
                    animation-iteration-count: 0;
                }
            }

            &.log-out {
                margin-right: $font-size-base;
            }
        }
    }
}

// ----------- Pop Hover Effect -----------

@-webkit-keyframes hvr-pop {
    50% {
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
    }
}

@keyframes hvr-pop {
    50% {
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
    }
}

.hvr-pop {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -moz-osx-font-smoothing: grayscale;
}

.hvr-pop:hover,
.hvr-pop:focus,
.hvr-pop:active {
    -webkit-animation-name: hvr-pop;
    animation-name: hvr-pop;
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
}

//-----------------------------------------------------------
// Feature Detection Fixes
//-----------------------------------------------------------

.no-csstransforms {
    #app-menu {
        .navbar-nav {
            > li {
                > a {
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}

//-----------------------------------------------------------
// Impersonating Styling for admins
//-----------------------------------------------------------

.impersonation-bar {
    text-align: center;
    padding: $unit-sm-3;
    font-size: 19px;
    font-weight: 400;
    letter-spacing: 0.2px;
    color: $color-dark-grey-3;
    background: radial-gradient(circle, $color-ui-warning-2, $color-ui-warning-5);
    box-shadow: 0px 1px 5px 0px $color-medium-grey-2;
}
