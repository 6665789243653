.master-admin-theme {
    .main-wrapper {
        width: 100%;
        padding-left: $unit-md-3;
        transition: all 0.3s ease-out;

        .main-content {
            padding: 0 $unit-base;
        }

        &.expanded {
            padding-left: $unit-lg-3;
        }
    }

    .sidebar {
        position: fixed;
        height: 100vh;
        width: $unit-md-3 + $unit-sm-2;
        top: 0;
        left: 0;
        z-index: 100;
        padding: 0;
        white-space: nowrap;
        overflow: hidden;
        background-color: $color-speechlink-dark-blue;
        transition: width 0.3s ease-out;
        box-shadow: 0 0 6px 0 $color-medium-grey-2;

        &.expanded {
            width: $unit-lg-3;

            .sidebar-brand {
                justify-content: space-between;

                img {
                    width: 55%;
                }
            }
        }
    }

    .sidebar-brand {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: $unit-md-3;
        padding: $unit-base;
        margin-bottom: $unit-base;
        background-color: $color-speechlink-blue;
        box-shadow: 0px 0px 6px 0px $color-speechlink-dark-blue;

        img {
            width: 0%;
        }
    }

    .sidebar-toggle {
        width: 26px;
        height: 26px;
        cursor: pointer;

        .hamburger {
            position: relative;
            height: 2px;
            width: 24px;
            background: $color-speechlink-light-blue;
            display: block;
            border-radius: 1px;
            content: "";

            &:before,
            &:after {
                height: 2px;
                width: 24px;
                background: $color-speechlink-light-blue;
                display: block;
                border-radius: 1px;
                content: "";
            }

            &:before {
                position: absolute;
                top: -7.5px;
                width: 18px;
            }

            &:after {
                position: absolute;
                bottom: -7.5px;
                width: 14px;
            }
        }

        &:hover {
            .hamburger {
                background: $color-paper;

                &:before,
                &:after {
                    background: $color-paper;
                }
            }
        }
    }

    .sidebar-group {
        .sidebar-heading {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 0;
            padding: $unit-base $unit-md-1;
            color: $color-paper;
            font-weight: 400;


            .sidebar-heading--text {
                i {
                    margin-right: $unit-sm-2;
                }
            }

            &:hover {
                background-color: $color-active-tab;
            }
        }

        .sidebar-group--item {
            display: flex;
            align-items: center;
            border-radius: 0;

            .bullet-point {
                display: flex;
                flex: 0 0 20px;
                align-items: center;

                span {
                    display: inline-block;
                    width: 4px;
                    height: 4px;
                    border-radius: 100%;
                    background-color: $color-speechlink-light-blue;
                }
            }

            &:hover {
                cursor: pointer;
                background-color: $color-active-tab-alt;
            }
        }

        .sidebar-group--link {
            display: flex;
            align-items: center;
            width: 100%;
            padding: $unit-sm-3 $unit-base;
            padding-left: $unit-md-2;
            color: $color-paper;

            &.sidebar-group--active {
                background-color: $color-active-tab-alt;
            }
        }

        &.sidebar-group--active {
            .sidebar-heading {
                background-color: $color-active-tab;
            }

            .sidebar-group--list {
                background-color: $color-speechlink-blue;
                box-shadow: inset 0 0 6px 0 $color-speechlink-dark-blue;
            }

            .sidebar-heading--text {
                color: $color-paper;
            }
        }
    }

    .sidebar-single {
        padding-top: $unit-base;
        padding-bottom: $unit-base;
    }
}
