//
// Bootstrap Grid system adjustment
// --------------------------------------------------


body {
	min-width: $screen-sm-min;
}

//----------------------------
// Enabling Flexbox - this will eventually become redundant
//----------------------------

.flex-container {
    display: flex;
}

//----------------------------
// Vertical margin-top Classes
//----------------------------

.margin-top-sm-1 {
    margin-top: $unit-sm-1;
}

.margin-top-sm-2 {
    margin-top: $unit-sm-2;
}

.margin-top-sm-3 {
    margin-top: $unit-sm-3;
}

.margin-top-base {
    margin-top: $font-size-base;
}

.margin-top-md-1 {
    margin-top: $unit-md-1;
}

.margin-top-md-2 {
    margin-top: $unit-md-2;
}

.margin-top-md-3 {
    margin-top: $unit-md-3;
}

.margin-top-lg-1 {
    margin-top: $unit-lg-1;
}

.margin-top-lg-2 {
    margin-top: $unit-lg-2;
}

.margin-top-lg-3 {
    margin-top: $unit-lg-3;
}

.margin-top-0 {
    margin-top: 0;
}

.first-margin-top-0:first-of-type {
    margin-top: 0;
}


//----------------------------
// Vertical margin-bottom Classes
//----------------------------

.margin-bottom-sm-1 {
    margin-bottom: $unit-sm-1;
}

.margin-bottom-sm-2 {
    margin-bottom: $unit-sm-2;
}

.margin-bottom-sm-3 {
    margin-bottom: $unit-sm-3;
}

.margin-bottom-base {
    margin-bottom: $font-size-base;
}

.margin-bottom-md-1 {
    margin-bottom: $unit-md-1;
}

.margin-bottom-md-2 {
    margin-bottom: $unit-md-2;
}

.margin-bottom-md-3 {
    margin-bottom: $unit-md-3;
}

.margin-bottom-lg-1 {
    margin-bottom: $unit-lg-1;
}

.margin-bottom-lg-2 {
    margin-bottom: $unit-lg-2;
}

.margin-bottom-lg-3 {
    margin-bottom: $unit-lg-3;
}

.margin-bottom-0 {
    margin-bottom: 0;
}

//----------------------------
// Horizontal margin-right Classes
//----------------------------

.margin-right-0 {
    margin-right: 0;
}

.margin-right-sm-1 {
    margin-right: $unit-sm-1;
}

.margin-right-sm-2 {
    margin-right: $unit-sm-2;
}

.margin-right-sm-3 {
    margin-right: $unit-sm-3;
}

.margin-right-base {
    margin-right: $font-size-base;
}

.margin-right-md-1 {
    margin-right: $unit-md-1;
}

.margin-right-md-2 {
    margin-right: $unit-md-2;
}

.margin-right-md-3 {
    margin-right: $unit-md-3;
}

.margin-right-lg-1 {
    margin-right: $unit-lg-1;
}

.margin-right-lg-2 {
    margin-right: $unit-lg-2;
}

.margin-right-lg-3 {
    margin-right: $unit-lg-3;
}

//----------------------------
// Horizontal margin-left Classes
//----------------------------

.margin-left-sm-1 {
    margin-left: $unit-sm-1;
}

.margin-left-sm-2 {
    margin-left: $unit-sm-2;
}

.margin-left-sm-3 {
    margin-left: $unit-sm-3;
}

.margin-left-base {
    margin-left: $font-size-base;
}

.margin-left-md-1 {
    margin-left: $unit-md-1;
}

.margin-left-md-2 {
    margin-left: $unit-md-2;
}

.margin-left-md-3 {
    margin-left: $unit-md-3;
}

.margin-left-lg-1 {
    margin-left: $unit-lg-1;
}

.margin-left-lg-2 {
    margin-left: $unit-lg-2;
}

.margin-left-lg-3 {
    margin-left: $unit-lg-3;
}

// Clear padding and margins

.no-padding {
	padding: 0;
}

//----------------------------
// Width classes
//----------------------------

.width-100 { // useful for textarea elements
    width: 100%;
    max-width: 100%;
}

.width-33-max {
    max-width: 33%;
}

.w-33 {
    width: 33% !important;
}

.width-30-max {
    max-width: 30%;
}

.w-30 {
    width: 30% !important;
}

.width-20-max {
    max-width: 20%;
}

.w-20 {
    width: 20% !important;
}

.width-15-max {
    max-width: 15%;
}

.w-15 {
    width: 15% !important;
}

.width-10-max {
    max-width: 10%;
}

.w-10 {
    width: 10% !important;
}

//----------------------------
// Hide Content Only for Print
//----------------------------

.print-only,
.print-only-caption {
    display: none;
}

//------------------------------------------------
// Hide Content so that it can be shown only to IE
//------------------------------------------------

.ie-only {
    display: none;
}


// Align items to the right of the Main Content Heading
.main-area-heading-align {
    margin: $unit-md-2 0 $unit-md-1 0;
    float: right;
}

//------------------------------------------------
// Bootstrap 4 alinement utilities
//------------------------------------------------

.align-baseline {
    vertical-align: baseline !important; 
} // Browser default

.align-top {
    vertical-align: top !important; 
}

.align-middle {
    vertical-align: middle !important; 
}

.align-bottom {
    vertical-align: bottom !important; 
}

.align-text-bottom {
    vertical-align: text-bottom !important; 
}

.align-text-top {
    vertical-align: text-top !important; 
}

//------------------------------------------------
// Layout Word Wrap Utilities
//------------------------------------------------

.break-word {
    word-break: break-word;
}
