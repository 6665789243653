.information-section {
	margin-bottom: $unit-md-3;
	font-family: $font-family-roboto;
	font-weight: 400;

	p {
		color: $color-medium-grey-4;

		&.text-info {
			color: $color-ui-info-3;
		}
	}

	&:last-of-type {
		margin-bottom: 0;
	}

	.resource-type {
		&.col-xs-4:nth-of-type(3n + 4) {
			clear: left;
		}

		&.col-xs-6:nth-of-type(2n + 3) {
			clear: left;
		}
	}

	// Flowplayer additions for Getting Started Videos
	.flowplayer {
		border: 1px solid $color-light-grey-4;
	}

	.sl-video {
		width: 100%;
		height: inherit;
		background: url("/images/brand/SpeechLink-Multimedia-logo-RGB-231x75.png") no-repeat center;
	}

    &:last-of-type {
        margin-bottom: 0;
    }

    .resource-type {
        &.col-xs-4:nth-of-type(3n+4) {
            clear: left;
        }

        &.col-xs-6:nth-of-type(2n+3) {
            clear: left;
        }
    }

    // Flowplayer additions for Getting Started Videos
    .flowplayer {
        border: 1px solid $color-light-grey-4;
    }

    .sl-video {
        width: 100%;
        height: inherit;
        background: url("/images/brand/SpeechLink-Multimedia-logo-RGB-231x75.png") no-repeat center;
    }

    .accordion-group {
        margin-bottom: 0;

        .card {
            margin-bottom: 0;

            &:first-child {
                .list-group-item {
                    border-top-right-radius: 4px;
                    border-top-left-radius: 4px;
                }
            }

            &:last-child {
                .list-group-item {
                    border-bottom-right-radius: 4px;
                    border-bottom-left-radius: 4px;
                }
            }
        }

        .list-group-item {
			border-radius: 0;
			cursor: pointer;
        }
    }
}

.information-resources {
	.resources-list {
		margin-bottom: 0;
		list-style-type: none;
		padding-left: 0;

		li {
			margin-bottom: 9px;

			&:last-oftype {
				margin-bottom: 0;
			}

			a {
				i {
					margin-right: 2px;
				}
			}
		}
	}
}

.information-resources,
.session-resources {
	.panel-title {
		.pdf-open-info {
			font-size: $h6-font-size;
			line-height: 1.5;
		}
	}
}

.error-page {
	.fa-exclamation-triangle {
		color: $color-ui-warning-3;
	}
}
