.icon-standard {
    color: $color-main-text !important;
}

.icon-success {
    color: $color-ui-success-2 !important;
}

.icon-success-2 {
    color: $color-ui-success-3 !important;
}

.icon-moderate {
    color: $color-ui-moderate-2 !important;
}

.icon-moderate-alt {
    color: $color-ui-moderate-5 !important;
}

.icon-borderline {
    color: $color-ui-moderate-3 !important;
}

.icon-failure {
    color: $color-ui-failure-2 !important;
}

.icon-warning {
    color: $color-ui-warning-2 !important;
}

.icon-warning-alt {
    color: $color-ui-warning-5 !important;
}

.icon-speechlink-blue {
    color: $color-speechlink-blue !important;
}

.icon-speechlink-green {
    color: $color-speechlink-green !important;
}

.icon-disabled {
    color: $color-medium-grey-4 !important;
}

.icon-info {
    color: $color-ui-info-3 !important;
}

.icon-speech-assessed {
    color: $color-speech-link !important;
}

.icon-targeted-message {
    color: $color-ui-warning-6 !important;
}

.icon-marketing-message {
    color: $color-ui-moderate-5 !important;
}

.error-title-icon {
    font-size: 3.5em;

    @include media-breakpoint-up(md) {
        font-size: 4.4em;
    }
}
