.ui-state-info {
    color: $color-ui-info-2;
}

.ui-state-warning {
    color: $color-ui-warning-3;
}

.ui-state-success {
    color: $color-ui-success-2;
}

.ui-state-failure {
    color: $color-ui-failure-2;
}

.ui-state-default {
    color: $color-ui-default-2;
}

.alert {
    width: auto;
    max-width: 40em;
    margin: $unit-md-1 auto;
    font-family: $font-family-sans-serif;

    h2,
    h3,
    h4 {
        margin-top: 0;
        font-weight: 400;
        font-size: 17px;
    }
}

.panel-body-report-builder {
    .alert {
        margin: 0px;
    }
}
