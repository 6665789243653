html,
body,
label,
.checkbox label,
.radio label,
label.checkbox-inline,
label.radio-inline {
    font-weight: 400;
    -webkit-text-size-adjust: 100%;
}

h1 {
    &.main-area-heading {
        margin: $unit-md-2 0 $unit-md-1 0;
    }
}

.word-wrap {
    white-space: normal;
    line-break: strict;
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
}

.inline-dl {
    overflow: auto;

    dt {
        float: left;
        clear: left;
        margin-right: $font-size-base;
        font-weight: 300;
    }

    dd {
        float: left;
        clear: none;
        font-weight: 400;
    }
}

.key-single-line {
    .key-item {
        display: inline-block;
        margin-right: $unit-base;
        white-space: nowrap;
    }
}

// Title for different states and content in static development views
code.static-markup {
    display: block;
    margin: $unit-md-3 0 $unit-sm-3 0;
    padding: 0 $unit-sm-1;
    border-top: 1px dashed $color-light-grey-4;
    border-bottom: 1px dashed $color-light-grey-4;
    background-color: transparent;
}

strong {
    font-weight: 700;
}

.text-info {
    font-size: $h6-font-size;
}

.icon-trial {
    color: $color-trial-bg;
    border: 1px solid $color-main-text;
}
