main {
    padding: $unit-md-2 $unit-base;
}

.jumbotron {
    margin: 0;
    padding: $unit-md-2 $unit-md-1;
    color: $color-main-text;
    background-color: $color-ui-info-1;

    h1 {
        color: $color-ui-success-4;
    }

    ul {
        padding-left: $unit-base;
        margin-bottom: 0;
    }

    hr {
        margin: $unit-base 0;
    }

    .lead {
        color: $color-dark-grey-4;
    }
}

.new-user-jumbotron {
    position: relative;

    h2 {
        margin-top: 0;
        font-family: $font-family-serif;
        font-size: 25px;
        font-weight: 400;
        text-align: center;
        text-transform: uppercase;
        text-shadow: 1px 1px 1px $color-medium-grey-3;
    }

    p {
        margin-bottom: 0;
        font-family: $font-family-roboto;
        font-size: 18px;
        font-weight: 400;
        line-height: 1.25;
        text-align: center;
        margin-bottom: 0;
    }

    .svg-container {
        position: absolute;
        left: -$unit-sm-3;
        top: -$unit-sm-3;
        z-index: 0;

        display: block;
        height: 117%;
        width: auto;
        overflow: visible;

        svg {
            position: relative;
            top: -1px;
            left: -1px;
            height: 111%;
            width: auto;
        }
    }

    .jumbotron-text {
        position: relative;
        margin-left: 35%;
        z-index: 10;
    }
}
