.staff-list {
    .panel-staff-member {
        &.panel {
            background-color: $color-light-grey-1;
        }

        .panel-heading {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            padding: $unit-sm-2;
            border-bottom: 1px solid $color-light-grey-4;
            border-radius: $border-radius-sm;

            .flex-staff-name {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                align-items: baseline;
                flex-grow: 2;
                line-height: 1.4;
            }

            .flex-staff-actions {
                display: flex;
                justify-content: flex-end;
                align-items: center;

                .btn {
                    margin-right: $unit-sm-2;

                    &:last-of-type {
                        margin-right: 0;
                    }
                }
            }
        }
    }
}

.no-flexbox {
    .staff-list {
        .panel-staff-member {
            .panel-heading {
                .flex-staff-name {
                    display: inline-block;
                }

                .flex-staff-actions {
                    float: right;
                    clear: none;
                }
            }
        }
    }
}
