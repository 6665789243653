// Default modal styles that can'r be changed using bootstrap overrides

.modal {
    font-family: $font-family-roboto-condensed;

    .modal-content {
        border-width: 0;

        .modal-header {
            padding: $unit-sm-3 $font-size-base;
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;

            .modal-title {
                margin-top: 0;
                margin-bottom: 0;
                font-weight: 400;
                font-size: 17px;
                line-height: 1.1;
            }
        }

        .modal-footer {
            text-align: left;
        }
    }

    &.modal-warning {
        .modal-content {
            .modal-header {
                color: $color-ui-warning-3;
                background-color: $color-ui-warning-1;
                border-bottom-color: $color-ui-warning-2;

                .close {
                    color: $color-ui-warning-6;
                }
            }
        }
    }

    &.modal-info {
        .modal-content {
            .modal-header {
                color: $color-ui-info-3;
                background-color: $color-ui-info-1;
                border-bottom-color: $color-ui-info-2;

                .close {
                    color: $color-ui-info-3;
                }
            }
        }
    }
}

.close {
    color: $color-dark-grey-3;
    opacity: 0.38;
}

// Custom knockout warning modal styles
.ko-warning-modal {
    z-index: 101;
    width: 396px;
    height: auto;
    position: fixed;
    left: 50%;
    top: 38%;
    margin-left: -198px;
    transform: translateY(-62%);

    .panel {
        margin-bottom: 0;
        box-shadow: 0 $unit-sm-1 $unit-sm-3 rgba($color-dark-grey-3, 0.38);
    }

    .panel-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0;

        .warning-modal-message {
            width: 100%;
            text-align: center;
            padding: 15px 15px 0 15px;
        }

        .warning-modal-btns {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 15px;
            border-top: 1px solid $color-medium-grey-1;
        }
    }
}

#modal-background-overlay {
    z-index: 100;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color-dark-grey-3, 0.62);
}
