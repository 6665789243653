// Oxygen Mono

@font-face {
    font-family: 'Oxygen Mono';
    src: url('/fonts/oxygen_mono/oxygenmono-regular-webfont.woff2') format('woff2'),
         url('/fonts/oxygen_mono/oxygenmono-regular-webfont.woff') format('woff'),
         url('/fonts/oxygen_mono/OxygenMono-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

// Dosis

@font-face {
    font-family: 'Dosis';
    src: url('/fonts/dosis/dosis-book-webfont.woff2') format('woff2'),
         url('/fonts/dosis/dosis-book-webfont.woff') format('woff'),
         url('/fonts/dosis/Dosis-Book.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Dosis';
    src: url('/fonts/dosis/dosis-medium-webfont.woff2') format('woff2'),
         url('/fonts/dosis/dosis-medium-webfont.woff') format('woff'),
         url('/fonts/dosis/Dosis-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Dosis';
    src: url('/fonts/dosis/dosis-semibold-webfont.woff2') format('woff2'),
         url('/fonts/dosis/dosis-semibold-webfont.woff') format('woff'),
         url('/fonts/dosis/Dosis-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

// Roboto 

@font-face {
    font-family: 'Roboto';
    src: url('/fonts/roboto/roboto-light-webfont.woff2') format('woff2'),
         url('/fonts/roboto/roboto-light-webfont.woff') format('woff'),
         url('/fonts/roboto/Roboto-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('/fonts/roboto/roboto-lightitalic-webfont.woff2') format('woff2'),
         url('/fonts/roboto/roboto-lightitalic-webfont.woff') format('woff'),
         url('/fonts/roboto/Roboto-Lightitalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto';
    src: url('/fonts/roboto/roboto-regular-webfont.woff2') format('woff2'),
         url('/fonts/roboto/roboto-regular-webfont.woff') format('woff'),
         url('/fonts/roboto/Roboto-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('/fonts/roboto/roboto-italic-webfont.woff2') format('woff2'),
         url('/fonts/roboto/roboto-italic-webfont.woff') format('woff'),
         url('/fonts/roboto/Roboto-Italic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto';
    src: url('/fonts/roboto/roboto-bold-webfont.woff2') format('woff2'),
         url('/fonts/roboto/roboto-bold-webfont.woff') format('woff'),
         url('/fonts/roboto/Roboto-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('/fonts/roboto/roboto-bolditalic-webfont.woff2') format('woff2'),
         url('/fonts/roboto/roboto-bolditalic-webfont.woff') format('woff'),
         url('/fonts/roboto/Roboto-BoldItalic.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
}

// Roboto Condensed 

@font-face {
    font-family: 'Roboto Condensed';
    src: url('/fonts/roboto_condensed/robotocondensed-light-webfont.woff2') format('woff2'),
         url('/fonts/roboto_condensed/robotocondensed-light-webfont.woff') format('woff'),
         url('/fonts/roboto_condensed/RobotoCondensed-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto Condensed';
    src: url('/fonts/roboto_condensed/robotocondensed-regular-webfont.woff2') format('woff2'),
         url('/fonts/roboto_condensed/robotocondensed-regular-webfont.woff') format('woff'),
         url('/fonts/roboto_condensed/RobotoCondensed-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto Condensed';
    src: url('/fonts/roboto_condensed/robotocondensed-bold-webfont.woff2') format('woff2'),
         url('/fonts/roboto_condensed/robotocondensed-bold-webfont.woff') format('woff'),
         url('/fonts/roboto_condensed/RobotoCondensed-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}


//Humanist Sans

@font-face {
    font-family: 'Humanist'; //	font-family: 'Humanist-Light';
    src: url('/fonts/humanist/261F65_0_0.eot');
    src: url('/fonts/humanist/261F65_0_0.eot?#iefix') format('embedded-opentype'),
        url('/fonts/humanist/261F65_0_0.woff') format('woff'),
        url('/fonts/humanist/261F65_0_0.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Humanist'; //	font-family: 'Humanist-LightItalic';
    src: url('/fonts/humanist/261F65_1_0.eot');
    src: url('/fonts/humanist/261F65_1_0.eot?#iefix') format('embedded-opentype'),
    url('/fonts/humanist/261F65_1_0.woff') format('woff'),
    url('/fonts/humanist/261F65_1_0.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Humanist'; //	font-family: 'Humanist-Bold';
    src: url('/fonts/humanist/261F65_4_0.eot');
    src: url('/fonts/humanist/261F65_4_0.eot?#iefix') format('embedded-opentype'),
    url('/fonts/humanist/261F65_4_0.woff') format('woff'),
    url('/fonts/humanist/261F65_4_0.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Humanist'; //	font-family: 'Humanist-BoldItalic';
    src: url('/fonts/humanist/261F65_5_0.eot');
    src: url('/fonts/humanist/261F65_5_0.eot?#iefix') format('embedded-opentype'),
    url('/fonts/humanist/261F65_5_0.woff') format('woff'),
    url('/fonts/humanist/261F65_5_0.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
}


@font-face { //	font-family: 'Humanist-Roman';
    font-family: 'Humanist';
    src: url('/fonts/humanist/261F65_2_0.eot');
    src: url('/fonts/humanist/261F65_2_0.eot?#iefix') format('embedded-opentype'),
    url('/fonts/humanist/261F65_2_0.woff') format('woff'),
    url('/fonts/humanist/261F65_2_0.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Humanist'; //	font-family: 'Humanist-Italic';
    src: url('/fonts/humanist/261F65_3_0.eot');
    src: url('/fonts/humanist/261F65_3_0.eot?#iefix') format('embedded-opentype'),
    url('/fonts/humanist/261F65_3_0.woff') format('woff'),
    url('/fonts/humanist/261F65_3_0.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
}

html, body, button {
    -webkit-font-smoothing: antialiased;
}
