//-----------------------------------------------------------
// Product Menu Specific Nav
//-----------------------------------------------------------

#product-menu {
    width: 100%;
    height: auto;

    .tab-menu {
        text-align: center;
        vertical-align: top;
        box-shadow: inset 0 -4px 5px -4px rgba(0, 0, 0, 0.6);
        margin-bottom: 0;

        > li {
            margin: 0;
            display: inline-block;
            vertical-align: top;

            @include media-breakpoint-up(md) {
                margin: 0 2px;
            }

            > a {
                display: block;
                border-width: 2px 2px 0 2px;
                border-style: solid;
                border-color: $color-medium-grey-1;
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
                box-shadow: inset 0 -4px 5px -4px rgba(0, 0, 0, 0.6);
                transition: 0.3s ease-in;

                &:hover,
                &:active,
                &:focus,
                &.active-tab {
                    color: white;
                    box-shadow: 0 -1px 3px rgba(0, 0, 0, 0.5);
                    outline: none;
                }
            }
        }
    }
}

//-----------------------------------------------------------
// Product Menu Drop Downs
//-----------------------------------------------------------

#product-menu {
    .tab-menu {
        > li {
            > ul {
                margin: 0;
            }

            &:hover > ul,
            &:active > ul,
            &:focus > ul {
                visibility: visible;
                opacity: 1;
            }

            ul {
                visibility: hidden;
                opacity: 0;
                transition: 0.3s ease-in;
                position: absolute;
                top: 112%;
                left: 0;
                width: 100%;
                min-width: 144px;
                border-top: 1px solid $color-light-grey-1;
                border-bottom: 1px solid darken($color-light-grey-1, 5%);
                border-right: 1px solid darken($color-light-grey-1, 5%);
                border-left: 1px solid darken($color-light-grey-1, 5%);
                padding: 8px 13px 5px 13px;
                z-index: 2000;
                background-color: $color-light-grey-1;
                border-bottom-right-radius: 5px;
                border-bottom-left-radius: 5px;
                box-shadow: 1px 1px 4px $color-medium-grey-3;
                font-size: 16px;
                line-height: 1.1;
                list-style: none;
                text-align: left;
                font-family: $font-family-dosis;
                font-size: 15px;
                font-weight: 500;

                li {
                    margin-bottom: $unit-sm-3;
                    position: relative;

                    &:last-of-type {
                        margin-bottom: $unit-sm-2;
                    }

                    a {
                        display: block;
                        transition-duration: 0.3s;

                        &:hover,
                        &:active,
                        &:focus {
                            color: $color-speechlink-blue;
                            transition-duration: 0.3s;
                        }
                    }
                }
            }

            &:last-of-type {
                > ul {
                    left: auto;
                    right: 0 !important;
                }
            }
        }
    }
}

.no-flexbox {
    #product-menu {
        .tab-menu {
            li {
                .drop1 {
                    top: 101%;
                }
            }
        }
    }
}

.touch {
    #product-menu {
        .tab-menu {
            > li {
                > a {
                    transition: none;
                }

                ul {
                    transition: none;

                    li {
                        a {
                            transition-duration: none;

                            &:hover,
                            &:active,
                            &:focus {
                                transition-duration: none;
                            }
                        }
                    }
                }
            }
        }
    }
}
