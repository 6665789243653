.nav-tabs.nav-tabs-admin {
    border-color: $color-medium-grey-1;
    padding-right: $unit-base;
    padding-left: $unit-base;
    border-radius: $border-radius-sm $border-radius-sm 0 0;
    background-color: $color-light-grey-3;
    font-family: $font-family-roboto-condensed;
    font-weight: 400;

    > li.active > a,
    > li.active > a:hover {
        border-color: $color-medium-grey-1;
        border-bottom-color: $color-light-grey-2;
        background-color: $color-light-grey-2;
        color: $color-dark-grey-1;
    }

    > li > a:hover {
        border-bottom-color: $color-medium-grey-1;
        background-color: $color-light-grey-2;
    }
}

.tab-content.tab-content-admin {
    .nav-tabs.nav-tabs-admin {
        background-color: transparent;

        > li.active > a,
        > li.active > a:hover {
            border-bottom-color: $color-medium-grey-1;
            background-color: $color-medium-grey-1;
            color: $color-dark-grey-1;
        }

        > li > a:hover {
            border-bottom-color: $color-medium-grey-1;
            background-color: $color-light-grey-2;
        }
    }
}
