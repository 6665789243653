.pupil-progress-measures {
    display: block;
    margin-top: $unit-sm-3;
    border: 1px solid $border-color;
    border-radius: $border-radius;
    background-color: $color-light-grey-3;
    font-family: $font-family-sans-serif;
    overflow: auto;

    // Defines full width row inside the progress measures
    .pm-row {
        border-bottom: 1px solid $border-color;

        &:last-of-type {
            border-bottom: 0;
        }
    }

    // Defines appropriate cell padding, can be applied directly to a row or to any element in a row. It matches the assessment result so refactoring is useful
    .pm-cell {
        padding: $unit-sm-2 $font-size-base;
    }

    // Styling specific to progress measures header - it matches the assessment result panel title row so we should look at refactoring if we have time
    .pm-header {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        align-content: center;

        h1,
        aside {
            flex: 0 1 auto;
        }

        h1 {
            margin: 0;
            padding: $unit-sm-1 $unit-sm-3 $unit-sm-1 $font-size-base;
            font-size: 16px;
            font-weight: 700;
            line-height: 1;
        }

        aside {
            padding: $unit-sm-1 $unit-sm-3 $unit-sm-1 $unit-sm-3;
            border-left: 1px solid $border-color;
            border-top-right-radius: $border-radius;
            color: $color-paper;
            font-weight: 400;

            &.btn-advised {
                background-color: $color-paper;
            }

            &.disabled {
                opacity: 0.65;
            }

            &.pm-reception {
                background-color: $color-infant-link;
            }

            &.pm-speech {
                background-color: $color-speech-link;
            }

            &.pm-junior {
                background-color: $color-junior-link;
            }

            &.pm-infant {
                background-color: $color-infant-link;
            }
        }
    }

    .pm-information {
        background-color: $color-light-grey-1;
    }

    .pm-data-row {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-around;
        align-items: stretch;
    }

    .pm-data-column {
        flex: 0 1 (100% / 3);

        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: stretch;
        border-right: 1px solid $color-medium-grey-1;

        &:last-child {
            border-right: 0;
        }

        .pm-full-height-wrap {
            flex: 1 1 100%;
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-start;
            align-items: stretch;
            width: 100%;
            min-height: 100%;
        }
    }

    .pm-data-box {
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        align-items: stretch; // Acting horizontally on pm-fieldset-flex
        min-height: 100%;
        width: 100%;
        font-size: 14px;
        line-height: 1;

        .pm-form-flex {
            flex: 1 0 auto;
            display: flex;
            flex-flow: column nowrap;
            justify-content: space-between;
            align-items: flex-end;
            min-height: 100%;
            padding-bottom: $unit-sm-3;

            .pm-fieldset {
                flex: 0 0 auto;
                width: 100%;
                margin-bottom: $unit-sm-3;
            }

            .pm-btn-wrap {
                flex: 0 0 auto;

                padding-right: 15px;
            }
        }

        .form-group,
        .form-button {
            margin-right: 0;
            margin-left: 0;
            flex-shrink: 1;

            .btn-group-justified {
                .btn {
                    font-weight: 600;
                }
            }
        }

        .pm-rating-label {
            @include media-breakpoint-up(md) {
                text-align: right;
                margin-bottom: 0;
                padding-top: 7px;

                &.pm-target-label {
                    padding-top: 4px;
                }
            }
        }

        label,
        input[type="number"] {
            white-space: nowrap;
        }

        small {
            font-size: 0.85em;
        }

        @include media-breakpoint-up(md) {
            font-size: $font-size-base;
        }

        input[type="number"] {
            padding: 0 0 0 $unit-sm-1;
            height: 25px;
            text-align: center;
        }

        table {
            width: 100%;
            margin-bottom: $unit-sm-1;

            .pm-section-title {
                margin-bottom: 0;
                padding-bottom: $font-size-base;
            }

            .pm-table-row {
                display: block;
                box-sizing: border-box;
                width: 100%;
                border-bottom: 1px dashed $color-medium-grey-1;
                overflow: auto;

                &:last-child {
                    border: 0;
                }

                .pm-data-name {
                    display: block;
                    box-sizing: border-box;
                    font-weight: 300;
                    width: 70%;
                    white-space: nowrap;
                    float: left;
                    clear: none;
                }

                .pm-data-value {
                    display: block;
                    box-sizing: border-box;
                    float: right;
                    clear: none;
                    width: 30%;
                    font-weight: 700;
                    text-align: right;
                }

                &.pm-data-total {
                    border-bottom: none;

                    .pm-data-name {
                        font-weight: 700;
                    }

                    .pm-data-name,
                    .pm-data-value {
                        padding-top: $unit-sm-3;
                        padding-bottom: $unit-sm-3;
                    }
                }

                &.pm-data-target {
                    border-top: 1px dashed $color-medium-grey-1;
                }
            }
        }
    }

    .pm-section-title {
        margin: 0;
        margin-bottom: $font-size-base / $line-height-base;
        border-bottom: 0;
        font-size: $font-size-base;
        font-weight: 700;
    }
}
