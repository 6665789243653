.card {
    border: 0;

    &:not(.card-new-message) {
        font-family: $font-family-sans-serif;
    }

    .card-header {
        padding: 10px 15px;
        border-top-right-radius: 3px;
        border-top-left-radius: 3px;

        .control-label.card-header-label {
            font-weight: 400;
            font-size: 17px;
            line-height: 1.3;
        }
    }

    .card-title {
        font-weight: 400;
        margin-top: 0;
        margin-bottom: 0;
        font-size: 1.133rem;
    }

    &.card-border {
        border-width: 1px;
        border-style: solid;
    }

    .card-body-title {
        margin-top: 0;
        border-bottom: 0;
        font-size: $font-size-base;
        font-weight: 700;
        color: inherit;
    }
}

.card-check-list {
    list-style-position: inside;
    padding-left: 0;

    &:last-child {
        margin-bottom: 0;
    }
}

.border-none {
    border: none;
}

.no-list-bullets {
    list-style-type: none;
}

.card-side-menu {
    border-top: 1px solid $color-medium-grey-1;
    margin-top: -10px;
}

.side-menu-heading {
    padding-top: 5px;
    padding-bottom: 5px;
    text-align: center;

    &.card-header {
        background-color: $color-light-grey-2;
    }

    .side-menu-icon {
        color: $color-paper;
        background: $color-medium-grey-2;
        width: $unit-md-1;
        height: $unit-md-1;
        border-radius: 50%;
        text-align: center;
        vertical-align: middle;
        padding: 18px;
        line-height: 1px;
        text-indent: -9px;
    }
}

.card-group {
    background-color: $color-light-grey-1;

    .card-header {
        border-bottom: 1px solid $color-light-grey-4;

        .control-label {
            font-weight: 400;
            font-size: 17px;
            line-height: 1.3;
        }
    }
}

.card-messages {
    .list-group {
        margin-bottom: 0;

        .list-group-item {
            display: flex;
            align-items: baseline;
            flex-wrap: nowrap;
            border-bottom: none;

            &:hover {
                cursor: pointer;
            }
        }

        &:last-of-type {
            .list-group-item {
                border-bottom: 1px solid $color-light-grey-4;
            }
        }
    }

    .sub-list {
        display: flex;
        flex-direction: column;
        padding: 15px;
        background-color: $color-paper;
        box-shadow: inset 0px 2px 10px -1px rgba(124, 124, 123, 0.4);
        border-right: 1px solid $color-light-grey-4;
        border-left: 1px solid $color-light-grey-4;

        a {
            margin-bottom: 10.5px;

            &:hover {
                cursor: pointer;
            }
        }

        time {
            align-self: flex-end;
            color: $color-medium-grey-2;
        }
    }
}

.card-warning {
    .list-group-item {
        color: $color-ui-warning-6;
    }
}

.card-primary {
    .list-group-item {
        color: $color-speechlink-blue;
    }
}

.card-primary-alt {
    .card-header {
        background-color: $color-ui-default-4;
        border-bottom: 1px solid $color-ui-default-5;
        color: $color-ui-default-5;
    }

    .list-group-item {
        color: $color-ui-default-5;
    }
}

.card-subscriptions {
    ol {
        padding-left: 15px;

        span {
            color: $color-medium-grey-3;
        }
    }
}

.card-info {
    .card-header {
        color: $color-ui-info-3;
        background-color: $color-ui-info-1;
        border-color: $color-ui-info-2;
    }
}

.card-add-class {
    .card {
        &:last-of-type {
            margin-bottom: 0;
        }
    }

    .pupil-select-all {
        display: flex;
        align-items: center;

        input[type="checkbox"] {
            margin-left: $unit-sm-2;
            margin-top: 0;
        }
    }

    .card-previous-class-heading {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .card-header {
        padding: $unit-sm-2;
        border-color: $color-light-grey-4;

        .flex-pupil-name {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .card-title {
                flex-basis: 55%;
            }

            .pupil-previous-class {
                flex-basis: 40%;
            }

            input[type="checkbox"] {
                flex-basis: 5%;
                margin: 0;
            }

            .fa-check {
                color: $color-medium-grey-4;
            }
        }
    }
}

.pupil-previous-header {
    font-size: 24px;
}

.no-flexboxlegacy {
    .card-messages {
        .list-group {
            .list-group-item {
                display: block;
            }
        }
    }
}
