.pdf-ready {
    background-color: white;
    color: black;

    .invoice-title {
        margin-top: $unit-md-1;
        margin-bottom: $unit-sm-3;
        font-size: $h2-font-size;
        text-transform: uppercase;
    }

    .inline-dl {
        dt {
            width: auto;
            min-width: 8em;
        }
    }

    .invoice-reference {
        margin-top: $unit-md-1;
        margin-bottom: $unit-base;
        font-size: $h4-font-size;
        text-align: center;
    }

    .panel-invoice {
        margin-bottom: $unit-md-1;
        background-color: $color-paper;
        font-size: $h6-font-size;
        line-height: 1.35;

        .table-bordered {
            border: 1px solid $color-medium-grey-1;
        }

        .highlight-row > th,
        .highlight-row > td {
            background-color: $color-light-grey-4;
        }

        .highlight-cell {
            font-weight: 700;
        }
    }

    .footer-text {
        margin-top: $unit-base;
        margin-bottom: $unit-sm-2;
        font-size: $h6-font-size * 0.9;
        line-height: 1;
    }

    .horizontal-spacing {
        padding-right: $unit-md-1 / 2;
        padding-left: $unit-md-1 / 2;
    }

    .pdf-open-info {
        font-size: $h6-font-size;
        line-height: $line-height-base;
    }
}
