// ------------------- //
// Bootstrap Overrides //
// ------------------- //

// Every Sass variable in Bootstrap 4 includes the !default flag allowing you to override the variable’s default value in your own Sass without modifying Bootstrap’s source code.

// Copy and paste variables as needed, modify their values, and remove the !default flag. If a variable has already been assigned, then it won’t be re-assigned by the default values in Bootstrap.

// You will find the complete list of Bootstrap’s variables in ./node_modules/bootstrap/scss/_variables.scss. Some variables are set to null, these variables don’t output the property unless they are overridden in your configuration.

// Variable overrides within the same Sass file can come before or after the default variables. However, when overriding across Sass files, your overrides must come before you import Bootstrap’s Sass files.


//
// Color system
//

$white:                          #fff;
$black:                          #000;

$primary:                          $color-speechlink-blue;
$secondary:                        $color-speechlink-green;
$success:                          $color-ui-success-3;
$info:                             $color-ui-info-3;
$warning:                          $color-ui-warning-3;
$danger:                           $color-ui-failure-3;

// Override the theme colors
$theme-colors: (
    "primary":                     $primary,
    "secondary":                   $secondary,
    "success":                     $success,
    "info":                        $info,
    "warning":                     $warning,
    "danger":                      $danger,
    "default":                     $white,
);

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer:                           $unit-base;


//== Media queries breakpoints
//
//## Define the breakpoints at which your layout will change, adapting to different screen sizes.

// Extra small screen / phone
$screen-xs-min:                    48rem;

// Small screen / tablet
$screen-sm-min:                    48rem;
$screen-tablet:                    $screen-sm-min;

// Medium screen / desktop
$screen-md-min:                    62rem;
$screen-desktop:                   $screen-md-min;

// Large screen / wide desktop
$screen-lg-min:                    75rem;
$screen-lg-desktop:                $screen-lg-min;


// Body
//
// Settings for the `<body>` element.

$body-bg:                          $color-paper;
$body-color:                       $color-dark-grey-1;


// Fonts
//
// Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif:           $font-family-roboto-condensed;
$font-family-serif:                $font-family-humanist;
$font-family-monospace:            $font-family-oxygen;
$font-family-base:                 $font-family-roboto;

$font-size-base:                   $unit-base; // Assumes the browser default, typically `16px`
$font-size-lg:                     calc($font-size-base * 1.27);  // ~18px
$font-size-sm:                     calc($font-size-base * .867);  // ~13px

$small-font-size:                  90%; // <small> html element

$font-weight-base:                 400;
$line-height-base:                 1.428571429;

$h1-font-size:                     calc($font-size-base * 2.1);   // ~31px
$h2-font-size:                     calc($font-size-base * 1.73);  // ~26px
$h3-font-size:                     calc($font-size-base * 1.47);  // ~22px
$h4-font-size:                     calc($font-size-base * 1.27);  // ~19px
$h5-font-size:                     $font-size-base;           // 15px
$h6-font-size:                     calc($font-size-base * 0.867); // ~13px

$display1-size:                    $h1-font-size;
$display2-size:                    $h2-font-size;
$display3-size:                    $h3-font-size;
$display4-size:                    $h4-font-size;

$card-heading-font-size:           calc($font-size-base * 1.133); // ~17px

$headings-font-weight:             300;
$headings-line-height:             1.1;


// Links
//
// Style anchor elements.

$link-color:                       $color-speechlink-dark-green;
$link-hover-color:                 $primary;
$link-hover-decoration:            none;


// Paragraphs
//
// Style p element.

$paragraph-margin-bottom:          $unit-sm-3;


// Components
//
// Define common padding and border radius sizes and more.

$line-height-lg:                   $line-height-base;
$line-height-sm:                   $line-height-base;

$line-height-computed:             floor(($font-size-base * $line-height-base));

$border-radius:                    $unit-sm-1;
$border-radius-lg:                 $unit-sm-2;
$border-radius-sm:                 calc($unit-sm-1 / $golden-ratio);

$box-shadow-sm:                    0 0.1333rem $unit-sm-1 rgba($black, .075);
$box-shadow:                       0 $unit-sm-3 $unit-base rgba($black, .15);
$box-shadow-lg:                    0 $unit-base 3rem rgba($black, .175);

$component-active-bg:              $primary;


// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

$table-cell-padding:               $unit-sm-3;
$table-cell-padding-sm:            $unit-sm-2;

$table-accent-bg:                  $color-light-grey-1;
$table-hover-bg:                   $color-light-grey-2;

$table-border-color:               $color-medium-grey-1;


// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

$input-btn-focus-width:            .1rem;

$btn-font-weight:                  500;
$btn-link-disabled-color:          $color-medium-grey-3;



// Forms

$label-margin-bottom:              $unit-sm-3;

$input-bg:                         $color-paper;
$input-disabled-bg:                $color-light-grey-2;
$input-color:                      $color-dark-grey-1;
$input-border-color:               $color-medium-grey-1;
$input-placeholder-color:          $color-medium-grey-3;


// Dropdowns
//
// Dropdown menu container and contents.

$dropdown-divider-bg:              $color-light-grey-3;
$dropdown-link-disabled-color:     $color-medium-grey-3;
$dropdown-header-color:            $color-medium-grey-3;


// Navs

$nav-link-padding-y:               $unit-sm-3;
$nav-link-padding-x:               $unit-base;
$nav-link-disabled-color:          $color-medium-grey-3;


// Navbar

$navbar-padding-x:                 $unit-base;
$navbar-padding-y:                 calc($line-height-computed / 2);
$navbar-nav-link-padding-x:        $unit-sm-3;


// Pagination

$pagination-padding-y:             $unit-sm-3;
$pagination-padding-x:             $unit-base;
$pagination-padding-y-sm:          $unit-sm-1;
$pagination-padding-x-sm:          $unit-sm-3;
$pagination-padding-y-lg:          $unit-base;
$pagination-padding-x-lg:          $unit-sm-3;
$pagination-active-color:          $white;
$pagination-disabled-color:        $color-medium-grey-3;


// Jumbotron

$jumbotron-padding:                $unit-md-1;


// Cards

$card-spacer-y:                    $unit-base;
$card-spacer-x:                    $unit-base;
$card-bg:                          $color-light-grey-2;
$card-img-overlay-padding:         $unit-sm-1;
$card-columns-gap:                 $unit-sm-1;
$card-columns-margin:              $card-spacer-y;


// Tooltips

$tooltip-max-width:                $unit-lg-3;
$tooltip-padding-y:                $unit-sm-1;
$tooltip-padding-x:                $unit-sm-3;


// Popovers

$popover-max-width:                $unit-lg-3;
$popover-box-shadow:               0 $unit-sm-1 $unit-sm-3 rgba($black, .2);

$popover-header-padding-y:         $unit-sm-3;
$popover-header-padding-x:         $unit-base;

$popover-arrow-width:              $unit-base;
$popover-arrow-height:             $unit-sm-3;


// Modals

// Padding applied to the modal body
$modal-inner-padding:              $unit-base;

$modal-dialog-margin:              $unit-sm-3;
$modal-dialog-margin-y-sm-up:      $unit-base;

$modal-title-line-height:          $line-height-base;
$modal-content-bg:                 $color-light-grey-2;

$modal-backdrop-bg:                $color-dark-grey-1;
$modal-backdrop-opacity:           0.62;
$modal-header-border-color:        $color-medium-grey-1;
$modal-footer-border-color:        $modal-header-border-color;

$modal-header-padding:             $unit-base;

$modal-lg:                         960px;
$modal-md:                         420px;
$modal-sm:                         320px;


// Alerts
//
// Define alert colors, border radius, and padding.

$alert-padding-y:                  $unit-base;
$alert-padding-x:                  $unit-base;
$alert-margin-bottom:              $unit-base;

// Progress bars

$progress-height:                  $unit-base;
$progress-bg:                      $color-light-grey-1;

// List group

$list-group-border-color:          $color-light-grey-4;

$list-group-item-padding-y:        $unit-base;
$list-group-item-padding-x:        $unit-base;

$list-group-hover-bg:              $color-light-grey-1;

$list-group-disabled-color:        $color-medium-grey-3;
$list-group-disabled-bg:           $color-light-grey-2;

// Image thumbnails

$thumbnail-padding:                $unit-sm-1;
$thumbnail-border-color:           $color-light-grey-2;

// Breadcrumbs

$breadcrumb-padding-y:             $unit-sm-3;
$breadcrumb-padding-x:             $unit-base;
$breadcrumb-item-padding:          $unit-sm-3;

$breadcrumb-margin-bottom:         $unit-base;

$breadcrumb-bg:                    $color-light-grey-1;
$breadcrumb-active-color:          $color-medium-grey-3;

// Code

$code-color:                       #C7254E;
$code-bg:                          #f9f2f4;

// Printing

$print-page-size:                   a4;
