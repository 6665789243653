// this class must be appended to the <body> tag in order to remove scrollbars
.gdpr-body-control {
    overflow: hidden;
}
/* POSITION CONTROL FOR COOKIES TO SLIDE IN/OUT */
.gdpr-wrapper.easein-transition .gdpr-sidebar {
    transition-timing-function: ease-out;
    transition: transform 600ms;
    transform: translateX(0%);
}
.gdpr-wrapper.easeout-transition .gdpr-sidebar {
    transition-timing-function: ease-in;
    transition: transform 1400ms;
    transform: translateX(120%);
}

.gdpr-wrapper {
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    outline: 0;
    width: 100%;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0);
    transition-timing-function: ease-in;
    transition: transform 1400ms;
    transform: translate(120%);
    z-index: 10000;

    &.easein-transition {
        transform: translate(0%);
        background-color: rgba(0, 0, 0, 0.62);
        transition: background-color 600ms ease;
    }

    &.easeout-transition {
        transform: translate(120%);
    }

    .gdpr-sidebar {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 30vw;
        min-width: 300px;
        overflow-x: hidden;
        overflow-y: scroll;
        background: $color-light-grey-2;
        font-family: $font-family-roboto-condensed;
        transform: translate(100%);

        @include media-breakpoint-down(md) {
            width: 35vw;
        }

        @include media-breakpoint-down(sm) {
            width: 30vw;
        }

        .close-sidebar {
            position: relative;
            margin: $unit-base;
            text-align: right;
            float: right;

            &:hover {
                cursor: pointer;
            }
        }

        .gdpr-main-info {
            padding: $unit-base;
        }

        /* Accordion styles */
        .cookie-feedback-good {
            color: $color-ui-success-3;
        }

        .cookie-feedback-bad {
            color: $color-ui-failure-2;
        }

        .cookie-feedback-partial {
            color: $color-ui-warning-3;
        }

        .cookie-feedback-required {
            color: $color-speechlink-blue;
        }

        .cookie-feedback-good,
        .cookie-feedback-bad,
        .cookie-feedback-partial,
        .cookie-feedback-required,
        .cookie-feedback-unknown {
            position: absolute;
            right: 40px;
            text-transform: uppercase;
            font-size: 12px;
            font-weight: 700;
            line-height: 1.25;
        }

        .tab {
            position: relative;
            width: 100%;
            overflow: hidden;
            border-top: 1px solid $color-ui-info-2;

            &:last-of-type {
                margin-bottom: $unit-md-1;
                border-bottom: 1px solid $color-ui-info-2;
            }
        }

        input {
            position: absolute;
            opacity: 0;
            z-index: -1;
        }

        label {
            position: relative;
            display: block;
            margin-bottom: 0;
            padding: $unit-base;
            font-weight: 400;
            background: $color-ui-info-1;
            line-height: 1;
            cursor: pointer;
        }

        .tab-content {
            max-height: 0;
            overflow: hidden;
            border-top-width: 0;
            border-top-style: solid;
            border-top-color: $color-ui-info-2;
            background: $color-ui-info-1;
            transition: max-height 0.2s;

            * {
                margin: 0 $unit-base $unit-base $unit-base;
            }

            ul {
                padding-left: 0;
            }

            .cookie-tip {
                position: relative;
                margin-bottom: $unit-sm-2;

                .cookie-tip-info-icon {
                    margin: 0 0 0 $unit-sm-1;
                    color: $color-ui-info-3;
                }

                .cookie-status {
                    margin: 0 0 0 $unit-sm-1;
                    float: right;
                    font-weight: 700;
                }

                .cookie-tip-text {
                    position: absolute;
                    bottom: $unit-sm-3;
                    left: 0;
                    border: 1px solid $color-ui-info-2;
                    padding: $unit-sm-3;
                    background-color: $color-light-grey-3;
                    border-radius: $border-radius;
                    box-shadow: 1px 1px 4px $color-medium-grey-3;
                    text-align: left;
                    z-index: 1;
                    visibility: hidden;
                    opacity: 0;
                    -webkit-transition: opacity 250ms cubic-bezier(0.23, 1, 0.32, 1);
                    transition: opacity 250ms cubic-bezier(0.23, 1, 0.32, 1);
                }

                &:hover .cookie-tip-text,
                &:focus .cookie-tip-text {
                    visibility: visible;
                    opacity: 1;
                    -webkit-transition: opacity 250ms cubic-bezier(0.755, 0.05, 0.855, 0.06);
                    transition: opacity 250ms cubic-bezier(0.755, 0.05, 0.855, 0.06);
                }
            }
        }

        /* :checked */
        input:checked ~ .tab-content {
            max-height: 100em;
            //border-top-width: 1px;
            transition: border-top 0.5s;
            transition: max-height 0.5s;
        }
        /* Icon */
        label::after {
            position: absolute;
            right: 0;
            top: 0;
            display: block;
            width: 3em;
            height: 3em;
            line-height: 3;
            text-align: center;
            transition: all 0.5s;
        }

        input[type="checkbox"] + label::after {
            content: "\25BC";
            transform: rotate(90deg);
            color: $color-ui-info-3;
        }

        input[type="checkbox"]:checked + label::after {
            transform: rotate(0deg);
            color: $color-ui-info-3;
        }
    }
}
