/* Cursors */
input[type="radio"] +, input[type="checkbox"] + {
    label, .fa, .far, .fas {
        cursor: pointer; 
    }
    label > {
        .fa, .far, .fas {
            cursor: pointer; 
        }
    }
}

/* Labels */
.fa-label {
    line-height: 1rem;
    margin-bottom: 0;

    input[type="checkbox"],
    input[type="radio"] {
        display: none;
    }
}

/* Unchecked Inputs */
input[type="checkbox"] +, 
input[type="checkbox"] + label >,
input[type="radio"] +,
input[type="radio"] + label > {
    .fa-check-circle:before { 
        content: "\f111"; /* .fa-circle */
    }
    .fa-dot-circle-o:before,
    .fa-check-circle-o:before {
        content: "\f10c"; /* .fa-circle-o */
    }
    .fa-circle:before {
        content: "\f1db"; /* .fa-circle-thin */
    }
    .fa-check:before,
    .fa-check-square-o:before {
        content: "\f0c8"; /* .fa-square-o */
    }
    .fa-check-square:before {
        content: "\f0c8"; /* .fa-square */
    }
}

/* Checked */
input[type="checkbox"]:checked +,
input[type="checkbox"]:checked + label >,
input[type="radio"]:checked +,
input[type="radio"]:checked + label > {
    .fa-check:before {
        content: "\f00c";
    }
    .fa-check-circle:before {
        content: "\f058";
    }
    .fa-check-circle-o:before {
        content: "\f05d";
    }
    .fa-check-square:before {
        content: "\f14a";
    }
    .fa-check-square-o:before {
        content: "\f046";
    }
    .fa-circle:before {
        content: "\f111";
    }
    .fa-dot-circle-o:before {
        content: "\f192";
    }
}
