//-----------------------------------------------------------
// Default Navbar Overrides not possible to do with variables
//-----------------------------------------------------------

#app-menu,
#product-menu {
	z-index: 0;
	.navbar-nav,
	.tab-menu {
		padding-left: 0;

	    > li {
	        margin: 0 8px 0 0;
			position: relative;

	        > a {
	            border-radius: 4px;
	            font-family: $font-family-dosis;
	            font-size: 14px;
	            font-weight: 500;
	            background-color: $color-light-grey-1;
	            line-height: 1;
	            border: 0;
				padding: $unit-sm-2 $unit-sm-2 7px $unit-sm-2 ;

				@include media-breakpoint-up(md) {
					font-size: 16px;
					padding-right: 10px;
					padding-left: 10px;
				}
	        }

	        .new-tab-cta {
	        	position: absolute;
	        	margin: 0px;
	        	top: -8px;
	        	right: -5px;
	        	color: $color-ui-warning-2;
    			animation: pulsate 1s ease-in-out infinite both;
	        }

			@keyframes pulsate {
				0% {
			        transform: scale(1);
				}
				50% {
			        transform: scale(1.5);
				}
				100% {
			        transform: scale(1);
				}
			}
	    }
	}
}
