#app-header {
    position: relative;
    border-bottom-width: 4px;
    border-bottom-style: solid;
}

#product-logo {
    width: 100%;
    height: 50px;
    margin: $unit-md-1 0;
    text-align: center;
}

#product-name {
    display: none;
}

#product-name,
#product-welcome {
    padding: 7px;
    font-family: $font-family-serif;
    font-size: 36px;
    line-height: 1;
}

.app-logo {
    display: block;
    top: 8px;
}

//---------------------------
// Admin Theme
//---------------------------

.admin-theme {
    #product-name {
        display: block;
        color: $color-speechlink-blue;
    }
}

//---------------------------
// Pupil Management Theme
//---------------------------

.pupil-management-theme {
    #product-name {
        display: block;
        color: #ffffff;
        text-shadow: 1px 1px 2px $color-dark-grey-2;
    }

    #product-logo {
        #product-name,
        #product-welcome {
            margin-top: 7px;
            margin-bottom: 0;
            color: $color-speechlink-dark-green;
            text-shadow: none;
        }
    }
}

//-----------------
// Speech Theme
//-----------------

.speech-theme {
    #product-logo {
        background: url("/images/brand/Speech-L-logo-RGB-154x50.png") no-repeat center center;
    }
}

//---------------
// Infant Theme
//---------------

.infant-theme {
    #product-logo {
        background: url("/images/brand/Infant-LL-logo-RGB-177x50.png") no-repeat center center;
    }
}

//----------------
// Junior Theme
//----------------

.junior-theme {
    #product-logo {
        background: url("/images/brand/Junior-LL-logo-RGB-177x50.png") no-repeat center center;
    }
}

// Change border colour to warning red if no js to match the information bar.
.no-js {
    .pupil-management-theme {
        #app-header {
            border-color: $color-ui-failure-3;
        }
    }
}
