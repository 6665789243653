legend {
    font-size: $h4-font-size;
    font-weight: 400;
}

.form-management {
    font-family: $font-family-sans-serif;

    .form-section {
        margin-top: $unit-md-1;

        &.form-section-spacing {
            margin-top: 0;
        }
    }

    input[type="text"],
    input[type="number"],
    input[type="date"],
    input[type="email"],
    input[type="password"],
    input[type="search"],
    input[type="tel"],
    textarea {
        box-shadow: inset 1px 1px 1px $color-medium-grey-1;
    }

    select {
        box-shadow: 1px 1px 1px $color-medium-grey-1;
    }

    label {
        font-weight: 400;
        line-height: 1.266666;
    }

    &.form-bg {
        padding: $font-size-base;
        background-color: $color-light-grey-3;
        border-radius: $border-radius;
    }

    .form-group-bg {
        padding: $unit-sm-3;
        background-color: $color-light-grey-1;
        border-radius: $border-radius-sm;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
    }

    .form-group-btn {
        margin-top: $font-size-base;
        margin-bottom: $font-size-base;
    }

    .form-field {
        padding: 0;
    }

    .btn-vertical-align {
        margin-top: 24px;
    }

    .form-control-static.present-dob {
        padding-top: 3px;
        padding-bottom: 2.5px;
        min-height: 35px;
    }

    .btn-edit-age {
        position: relative;
        top: -1px;
    }

    .form-control.input-edit-age {
        display: inline;
        line-height: 1;
    }

    .form-code {
        color: $code-color;
        background-color: $code-bg;
        font-family: $font-family-monospace;
        font-size: 90%;
        padding-left: $unit-sm-2;
        padding-right: $unit-sm-2;
    }

    .label-title {
        display: block;
    }

    // Alerts that appear inside forms - these are not the same as form validation
    .alert {
        &.form-alert {
            position: relative;
            margin: 0;
            padding: $unit-sm-2;
            text-align: center;
        }

        &.arrow-top-left {
            margin-top: $unit-sm-1;

            &:after {
                content: "";
                display: block;
                position: absolute;
                top: -$font-size-base;
                left: $font-size-base;
                width: 0;
                height: 0;
                border-color: transparent transparent $color-ui-warning-2 transparent;
                border-style: solid;
                border-width: $font-size-base / 2;
            }
        }
    }

    // Key as part of form within a panel
    .panel-form-key {
        margin-bottom: 0px;

        dt {
            font-weight: 700;
            text-align: center;
            width: 50px;
            overflow: visible;
        }

        dd {
            font-weight: 300;
            margin-left: $unit-md-3;
            margin-bottom: $unit-sm-3;
        }
    }
}

.login-form {
    input {
        font-family: $font-family-oxygen;
    }
}

/*-----------------------------------------
/ Colours for the EAL Scale Status Letter
/----------------------------------------*/

.eal-scale-A {
    color: $color-ui-failure-4;
}

.eal-scale-B {
    color: $color-ui-warning-5;
}

.eal-scale-C {
    color: $color-ui-moderate-5;
}

.eal-scale-D {
    color: $color-ui-success-3;
}

.eal-scale-E {
    color: $color-ui-success-4;
}

/*-----------------------------------
/ Form Validation Styling
/----------------------------------*/

form,
.session-warning-data,
.form-styles {
    select {
        appearance: none;

        &:required,
        &:required.form-control {
            padding-right: $unit-base;
            padding-left: $unit-md-1;
            background-image: url("/images/ui/question-circle.svg"), url("/images/ui/chevron-down.svg");
            background-position: $unit-sm-2 center, calc(100% - 4px) center;
            background-size: $font-size-base $font-size-base, $font-size-base * 0.8 $font-size-base * 0.8;
            background-repeat: no-repeat, no-repeat;

            &:valid,
            &:in-range {
                border-color: $color-ui-success-2;
                background-image: url("/images/ui/check-circle.svg"), url("/images/ui/chevron-down.svg");
            }

            &:focus:invalid,
            &:focus:out-of-range {
                border-color: $color-ui-warning-5;
                background-image: url("/images/ui/minus-circle.svg"), url("/images/ui/chevron-down.svg");
            }
        }

        &:not(:required),
        &:not(:required).form-control {
            padding-right: $unit-base;
            background-image: url("/images/ui/chevron-down.svg");
            background-position: calc(100% - 4px) center;
            background-size: $font-size-base * 0.8 $font-size-base * 0.8;
            background-repeat: no-repeat;
        }
    }

    &:not([novalidate]) {
        input,
        textarea {
            &.form-control {
                &:required {
                    &:valid,
                    &:in-range {
                        border-color: $color-ui-success-2;
                    }

                    &:invalid,
                    &:out-of-range {
                        &:focus {
                            border-color: $color-ui-warning-5;
                        }
                    }
                }
            }
        }

        .validation-message-wrapper {
            position: relative;
        }

        .valid-message,
        .invalid-message {
            z-index: 1000;
            position: absolute;
            display: none;
            top: 0;
            right: $font-size-base;
            padding: ($unit-sm-1/2) $unit-sm-2;
            height: 23px;
            border-radius: $border-radius-sm;
            font-family: $font-family-sans-serif;
            font-size: $h6-font-size;
            font-weight: 500;

            &:after {
                content: "";
                display: block;
                position: absolute;
                top: $h6-font-size + $unit-sm-1 + $unit-sm-2;
                right: $unit-sm-2;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: $unit-sm-1;
            }
        }

        &:not(.pm-data-box) {
            input.form-control,
            textarea {
                padding-left: $unit-md-1;
                background-position: $unit-sm-2 center;
                background-size: $font-size-base $font-size-base;
                background-repeat: no-repeat;

                &:required {
                    &:valid,
                    &:in-range {
                        background-image: url("/images/ui/check-circle.svg");

                        &:focus ~ .valid-message {
                            display: inline-block;
                            color: $color-paper;
                            background-color: $color-ui-success-2;

                            &:after {
                                border-color: $color-ui-success-2 transparent transparent transparent;
                            }
                        }
                    }

                    &:invalid,
                    &:out-of-range {
                        background-image: url("/images/ui/question-circle.svg");

                        &:focus {
                            background-image: url("/images/ui/minus-circle.svg");

                            & ~ .invalid-message {
                                display: inline-block;
                                color: $color-dark-grey-2;
                                background-color: $color-ui-warning-5;

                                &:after {
                                    border-color: $color-ui-warning-5 transparent transparent transparent;
                                }
                            }
                        }
                    }
                }
            }

            textarea {
                background-position: $unit-sm-2 $unit-sm-3;
            }

            &.form-horizontal,
            .input-group {
                .valid-message,
                .invalid-message {
                    top: -($h6-font-size + $unit-sm-2 + $unit-sm-2);
                }
            }

            .invalid-value {
                input.form-control {
                    &:invalid,
                    &:out-of-range,
                    &:required:invalid,
                    &:required:out-of-range {
                        border-color: $color-medium-grey-1;
                        background-image: url("/images/ui/question-circle.svg");
                    }

                    &:valid,
                    &:in-range &:required:valid,
                    &:required:in-range,
                    &:focus:valid,
                    &:focus:in-range,
                    &:focus:required:valid,
                    &:focus:required:in-range,
                    &:focus:invalid,
                    &:focus:out-of-range,
                    &:focus:required:invalid,
                    &:focus:required:out-of-range {
                        //color: $color-ui-warning-6;
                        border-color: $color-ui-warning-5;
                        background-image: url("/images/ui/minus-circle.svg");
                        background-position: $unit-sm-2 center;
                        background-size: $font-size-base $font-size-base;
                        background-repeat: no-repeat;

                        & ~ .invalid-message {
                            display: inline-block;
                            color: $color-dark-grey-2;
                            background-color: $color-ui-warning-5;

                            &:after {
                                border-color: $color-ui-warning-5 transparent transparent transparent;
                            }
                        }

                        & ~ .valid-message {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

// Form Validation Style Corrections for versions of IE - you can't reset select styles
.no-appearance {
    form,
    .session-warning-data,
    .form-styles {
        select {
            &:required,
            &:required.form-control {
                background-image: none none;
                background-size: 0;

                &:valid,
                &:in-range {
                    border-color: $color-ui-success-2;
                    background-image: none none;
                }

                &:focus:invalid,
                &:focus:out-of-range {
                    border-color: $color-ui-warning-5;
                    background-image: none none;
                }
            }

            &:not(:required),
            &:not(:required).form-control {
                background-image: none;
            }
        }
    }
}

// Styling for the Login Form
.login-form {
    .form-group {
        position: relative;
    }

    .parsley-errors-list {
        top: -28px;
    }
}

.media-upload {
    label {
        display: block;
        font-weight: 700;
    }

    .media-upload__file {
        display: block;
        padding: $unit-sm-3;
        background-color: $color-speechlink-light-green;
        border-radius: $unit-sm-1;
        margin-bottom: $unit-sm-3;
    }

    .media-upload__noFile {
        display: block;
        padding: $unit-sm-3;
        background-color: $color-ui-warning-1;
        border-radius: $unit-sm-1;
        margin-bottom: $unit-sm-3;
    }
}
