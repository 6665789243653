.card-master-admin {
    .card-header,
    .card-footer {
        &.inline-content {
            overflow: auto;

            .inline-content-element,
            .card-title,
            .card-title-extra-info {
                display: inline-block;
                line-height: 1;
            }

            .card-title-extra-info {
                margin-left: $unit-base;
            }

            .pagination {
                margin: 0;
            }
        }

        &.with-pagination {
            padding-bottom: 3px;

            .form-inline {
                margin-bottom: 7px;
            }
        }
    }

    .table.table-in-card > thead > tr > th,
    .table-bordered.table-in-card > thead > tr > th {
        border-bottom-width: 1px;

        &.border-right {
            border-right: 1px solid $table-border-color !important;
        }

        &.border-left {
            border-left: 1px solid $table-border-color !important;
        }
    }

    .table.table-in-card > thead > tr {
        background-color: $color-light-grey-3;
    }

    .table.table-in-card > thead > tr > td,
    .table.table-in-card > thead > tr > th {
        border-top: 0;
    }

    .td-success,
    .td-danger {
        font-weight: 700;
        color: $color-paper;
    }

    .td-success {
        background-color: $color-ui-success-3;
    }

    .td-danger {
        background-color: $color-ui-failure-3;
    }

    .moderate,
    .moderate16 {
        background-color: $color-ui-moderate-2;
        color: $color-paper;
    }

    .severe {
        background-color: $color-ui-failure-2;
        color: $color-paper;
    }

    .ok {
        background-color: $color-ui-info-2;
        color: $color-paper;
    }

    .archived {
        background-color: $color-ui-warning-1;
    }

    .pupil-assessed-severe {
        color: $color-ui-failure-2;
    }

    .pupil-assessed-moderate,
    .pupil-assessed-moderate16 {
        color: $color-ui-moderate-2;
    }

    .pupil-assessed-ok {
        color: $color-ui-info-2;
    }
}

.form-master-admin {
    .form-group-title {
        font-size: $font-size-base;
        margin-bottom: $unit-sm-1;
    }

    .form-group-sub-title {
        font-size: $font-size-base;
        margin-top: 0;
    }

    #sen-statuses {
        min-height: 8em;
        height: auto;
    }

    .card-body + .table-in-card:not(:last-child) > tbody tr:last-child td,
    .table-in-card + .table-in-card:not(:last-child) > tbody tr:last-child td {
        border-bottom: 1px solid $color-medium-grey-1;
    }

    .card-body + .table-in-card:not(:last-child) + .card-footer,
    .table-in-card + .table-in-card:not(:last-child) + .card-footer {
        border-top: none;
    }

    .table > tbody + tbody {
        border-top-width: 1px;
    }
}

.buffer-horizontal {
    margin-right: $unit-base;
}
